.action-container {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.action {
  --selectedBg: var(--color-primary);
  background-color: var(--selectedBg);
  --size: 20px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  color: var(--color-primary);
  border: none;
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid black;
}

.selected {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}
.selected:hover {
}
.action-container:not(.selected):hover {
  background-color: var(--light-grey);
}

.action.no-hover:hover {
  background-color: transparent;
}

.popover {
  user-select: none;
  background: var(--light-black);
  font-size: 12px;
  color: #fff;
  padding: 0.5em 0.8em;
  letter-spacing: 0.3px;
  line-height: 16px;
  border: 0px;
  border-radius: 4px;
  text-transform: capitalize;
}
