.canvas {
  width: 100%;
  height: 100%;
}
.grid {
  display: grid;
  height: 100%;
  place-content: center;
  grid-auto-flow: column;
  grid-gap: 60px;
}
.preview-container {
  width: calc(270px * 2);
  height: 388px;
}

.center {
  align-self: center;
}

.preview-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 30px;
}
.controls {
  /* border: 1px solid black; */
  /* width: calc(270px * 2); */
  /* height: 388px; */
}

.hidden-print {
  visibility: hidden;

  position: absolute;
  top: 0;
}

@media print {
  header {
    display: none;
  }
  .hidden-print {
    visibility: visible;
    /* display: block; */
    position: static;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .grid {
    display: none;
  }
  body {
    /* overflow: hidden; */
    display: block;
    height: 100vh;
    max-height: 100vh;
    margin: 0;
    padding: 0;
  }
  @page {
    size: A4 landscape;
    margin: 0;
  }
  /* .pb_after {
    page-break-after: always !important;
  } */
}
