.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: none;
  background-size: cover;
}
.flip-page {
  background-color: #fdfaf7;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}
.flip-page .flip-page-content {
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  --radius: 20px;
  border: 4px solid var(--blue-gray100);
  width: var(--editor-width);
  width: 270px;
  height: var(--editor-height);
  /* transform: scale(0.333); */
  transform-origin: top left;
  border-radius: var(--radius);
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.flip-page .flip-page-content .flip-page-header {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}
.flip-page .flip-page-content .flip-page-image {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.flip-page .flip-page-content .flip-page-text {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}
.flip-page .flip-page-content .flip-page-footer {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
}
.flip-page.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.flip-page.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.flip-page.--right .flip-page-footer {
  text-align: right;
}
.flip-page.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}
.flip-page.flip-page-cover {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
}
.flip-page.flip-page-cover h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}
.flip-page.flip-page-cover.flip-page-cover-top {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}
.flip-page.flip-page-cover.flip-page-cover-bottom {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    10px 0 8px 0px rgba(0, 0, 0, 0.4);
}
