
.canvas {
  width: 100%;
  height: 100%;
}
.grid {
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);

  grid-auto-flow: column;
  grid-template-areas:
    "page6 page5 page4 page3"
    "back front page1 page2";
  width: min-content;
  --border-color: transparent;
  --cut-border-color: black;
  position: relative;
}

.cut-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-707%, -67%) rotate(103deg);
  z-index: 20;
  font-size: 50px;
  color: var(--color-primary);
}

.page {
  height: var(--editor-height);
  /* max-height: var(--editor-height); */
  width: var(--editor-width);

  border: 1px dashed var(--border-color, transparent);
}
.front {
  grid-area: front;
  border-top-color: var(--cut-border-color);
}
.back {
  grid-area: back;
}

.page1 {
  grid-area: page1;
  border-top-color: var(--cut-border-color);
}
.page2 {
  grid-area: page2;
}
.page3 {
  grid-area: page3;
  transform: scaleX(-1) scaleY(-1);
}
.page4 {
  grid-area: page4;
  transform: scaleX(-1) scaleY(-1);

  border-top-color: var(--cut-border-color);
}
.page5 {
  grid-area: page5;
  transform: scaleX(-1) scaleY(-1);
  border-top-color: var(--cut-border-color);
}
.page6 {
  grid-area: page6;
  transform: scaleX(-1) scaleY(-1);
}

/* @page {
  size: A4 landscape;
  margin: 0;
} */

@media print {
  .cut-icon {
    display: none;
  }
}
/* old print media */
@media print2 {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .grid {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    /* --editor-height: 1fr;
    --editor-width: 1fr; */
    /* margin: -10px; */

    /* /* max-width: calc(100% - 2%); */
    /* grid-gap: 2px;
    grid-template-columns: repeat(4, var(--editor-width));

    grid-template-rows: repeat(2, var(--editor-height)); */
  }

  .page {
    border: none;
  }

  .page6 {
    /* border-left: black solid 1px; */
  }
}
