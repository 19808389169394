.page {
  display: grid;
  grid-template-rows: min-content auto;
  align-items: center;
  height: 100%;
  /* background-color: var(--offwhite); */
  background-color: var(--color-background);
}
.header {
  height: 100%;
  display: flex;
  justify-content: center;
}
.login {
  display: grid;
  grid-row: 1/1;
  grid-column: 1/1;
  gap: 20px;
}

.beta {
  font-size: 13px;
}

.title-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.form-title {
  font-size: 1.7rem;
  color: var(--color-primary);
  text-align: center;
  text-transform: capitalize;
  font-family: var(--font-Nunito-bold);
}

.logo {
  color: var(--color-primary);
  font-family: var(--font-Nunito-bold);
  font-size: 67px;
}

.logo-icon {
  height: 50px;
  padding-left: 10px;
  opacity: 0;
}

.logo-icon-folding {
  height: 250px;
  padding-left: 25px;
}

.show-logo-icon {
  opacity: 1;
}

.logo-top {
  composes: logo;
  font-size: 1.4rem;
  padding: 0.7rem;
  opacity: 0;
}

.show-top-logo {
  opacity: 1;
}

.paper-container {
  /* transform: translateY(50%);
  transition: all 2s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  transition-delay: 2s; */
  animation-name: slideUp;
  animation-duration: 700ms;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  /* animation-timing-function: cubic-bezier(0.42, 0, 1, 1.4); */
  animation-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
  transform: translateY(100%);
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-5%);
  }
}

.paper {
  grid-area: paper;

  --space-root: 1rem;
  --space-xs: calc(var(--space-root) / 2);
  --space-s: calc(var(--space-root) / 1.5);
  --space-m: var(--space-root);
  --space-l: calc(var(--space-root) * 1.5);
  --space-xl: calc(var(--space-root) * 2);
  --base-border-radius: 2rem;
  --ease: cubic-bezier(0.075, 0.82, 0.165, 1);
  --duration: 700ms;
  /* --duration: 350ms; */
  --font-family: "Roboto", sans-serif;
  --font-size: 1.25rem;
  position: relative;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  transform: skewY(-5deg) translateY(10%) scale(0.94);
  transition: box-shadow var(--duration) var(--ease), transform var(--duration) var(--ease);
}
.paper:before,
.paper:after {
  content: "";
  position: absolute;
  pointer-events: none;
  background-color: #ebebeb;
  width: 25%;
  height: 100%;
  transition: background-color var(--duration) var(--ease), transform var(--duration) var(--ease);
}
.paper:before {
  top: 0;
  right: calc(100% - 1px);
  transform-origin: 100% 100%;
  transform: skewY(-35deg) scaleX(-1);
  z-index: -1;
}
.paper:after {
  top: 0;
  left: calc(100% - 1px);
  transform-origin: 0 0;
  transform: skewY(-35deg) scaleX(-1);
  z-index: 2;
}
.paper:focus-within .back,
.paper:hover .back {
  opacity: 1;
}
.paper:focus-within .front,
.paper:hover .front {
  opacity: 0;
  visibility: hidden;
  /* position: absolute; */
}
.paper:hover,
.paper:focus-within {
  transform: scale(1.0001);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
}
.paper:hover:before,
.paper:hover:after,
.paper:focus-within:before,
.paper:focus-within:after {
  background-color: white;
  transform: skewY(0);
}

.paper-inner {
  padding: var(--space-xl);
  background-color: white;
  z-index: 1;
  display: grid;
  /* flex-direction: column; */
  gap: 10px;
}

.front {
  grid-column: 1/1;
  grid-row: 1/1;
  display: grid;
  gap: 1.6rem;
  grid-template-rows: min-content;
}

.typing {
  padding-left: 0.4rem;
  -webkit-font-smoothing: antialiased;
}

.typing-cursor {
  height: 10rem;
  width: 1px;
  background-color: var(--color-primary);
}
.input-wrapper:focus-within .label {
  color: var(--black);
}
.input-wrapper:focus-within .icon {
  background-color: var(--black);
}
.input-wrapper:focus-within input {
  border-color: var(--black);
}
.input-wrapper + .input-wrapper {
  margin-top: var(--space-l);
}

.input-group {
  position: relative;
}
.input-group input {
  border-radius: var(--base-border-radius);
  padding-left: calc(var(--space-s) + 60px);
}
.input-group .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top-left-radius: var(--base-border-radius);
  border-bottom-left-radius: var(--base-border-radius);
  pointer-events: none;
}

.input-hidden {
  appearance: none;
  /* visibility: hidden; */

  opacity: 0;
  position: absolute;
  top: -200%;
  pointer-events: none;
}

.label {
  padding-left: 1rem;
  font-size: calc(var(--font-size) / 1.6);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.065rem;
  display: block;
  margin-bottom: var(--space-xs);
  color: var(--color-primary);
}

.icon {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  padding: var(--space-m);
  background-color: var(--color-primary);
}
.icon svg {
  width: 1.25em;
  height: 1.25em;
  fill: white;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform var(--duration) var(--ease);
}

.input {
  flex: 1 1 0;
  width: 100%;
  outline: none;
  padding: var(--space-s);
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--black);
  border: 2px solid var(--color-primary);
}
.input:focus {
  color: var(--color-primary);
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-l);
}
.btn-group > * + * {
  margin-left: var(--space-s);
}

.front {
  opacity: 1;
}
.back {
  opacity: 0;
}

.separator {
  height: 1px;
  background-color: var(--blue-gray100);
}

.third-parties {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 10px;
}
