.demo-menu {
  background: #fff;
  /* margin: auto; */
  /* margin-top: 0.8rem; */

  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0.2s;
  will-change: transform;
  text-align: left;
  position: absolute;
  width: fit-content;
  /* left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto; */
  z-index: 2;
}

.demo-menu.visible {
  transform: translateY(-0.3rem);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.demo-menu a {
  display: block;
  font-size: 1.2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.demo-menu .menu-item {
  color: #29779e;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  border: 0.1rem solid transparent;
  padding: 10px 15px;
}

.demo-menu .menu-item:hover {
  background-color: #e9e9e9;
}

.demo-menu a:hover {
  text-decoration: underline;
}

/* Fallback for browsers that do not support :focus-visible. */
.demo-menu a:focus {
  border-color: #3a8eb8;
}

.demo-menu a:focus:not(:focus-visible) {
  border-color: transparent;
}

.demo-menu a:focus-visible {
  border-color: #3a8eb8;
}

.demo-menu a svg {
  color: #777;
  margin-right: 0.5rem;
}

ul.behavior {
  width: fit-content;
  max-width: 50rem;
  margin: 12rem auto 0;
  text-align: left;
  line-height: 1.3;
}

ul.behavior li {
  padding: 0.25rem 0;
}
