.action {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: var(--color-primary);
  background-color: transparent;
  border: none;
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 24px;
  cursor: pointer;
}
.action:not(.selected):hover {
  background-color: var(--light-grey);
}
.open {
  background-color: var(--light-grey);
}

.popover {
  user-select: none;
  background: var(--color-secondary);
  font-size: 12px;
  color: #fff;
  padding: 0.5em 0.8em;
  letter-spacing: 0.3px;
  line-height: 16px;
  border: 0px;
  border-radius: 4px;
  display: flex;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
}
