.action {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color-primary);
  background-color: transparent;
  border: none;
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 24px;
  cursor: pointer !important;
}

.float {
  background: var(--color-secondary);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.float:active {
  transform: translate(0px, 2px);
  transition: transform 100ms;
}

.selected {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.action:not(.selected, .float):hover {
  background-color: var(--light-grey);
}

.action.no-hover:hover {
  background-color: transparent;
}
.selected:hover {
  background-color: var(--color-primary) !important;
}

.popover {
  user-select: none;
  background: var(--light-black);
  font-size: 12px;
  color: #fff;
  padding: 0.5em 0.8em;
  letter-spacing: 0.3px;
  line-height: 16px;
  border: 0px;
  border-radius: 4px;
}
