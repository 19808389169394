.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  background: var(--color-primary);
  border-radius: 100px;
  color: var(--color-secondary);
  border: 0;
  font-family: "Rubik-Medium" !important;
  cursor: pointer;
  border: 1px solid var(--color-primary);
}
.normal {
}
.large {
  font-size: 18px;
}
.full-width {
  width: 100%;
}
.button:hover {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border: 1px solid var(--color-primary);
}
