.grid {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  background-color: var(--color-background);
}
.actions-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.actions {
  background: var(--color-secondary);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px rgba(60, 64, 67, 0.15);
  border-radius: 100px;
  height: 56px;
  padding-inline: 8px;
  width: max-content;
  display: flex;
  place-content: center;
  place-items: center;
}
.actions-static {
  display: flex;
  gap: 10px;
}

.actions-dynamic {
  display: flex;
  gap: 10px;
  align-items: center;
}

.colors-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.upload-image-input {
  display: none;
}

.editors {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  flex-shrink: 0;
  flex-grow: 1;
}

.editor {
  height: var(--editor-height);
  /* max-height: var(--editor-height); */
  width: var(--editor-width);
}

.previews-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
}

.preview-drawer {
  background: var(--color-secondary);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  color: var(--blue-gray700);
  padding: 1px 10px 1px 5px;
  gap: 2px;
  position: relative;
}
.preview-arrow {
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-title {
  font-size: 16px;
  font-family: "Nunito-ExtraBold";
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.previews {
  display: grid;
  padding: 35px 35px 0 35px;
  grid-auto-flow: column;
  grid-template-rows: 150px;
  overflow: auto;
  justify-content: center;
  background-color: var(--color-secondary);
  border-radius: 25px 25px 0px 0px;
  color: var(--blue-gray600);
  flex-shrink: 0;
  cursor: pointer !important;
}

.preview-container {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.preview {
  --radius: 20px;
  border: 4px solid var(--blue-gray100);
  width: var(--editor-width);
  height: var(--editor-height);
  transform: scale(0.333);
  transform-origin: center;
  border-radius: var(--radius);
  position: relative;
}
.preview::before {
  --preview-color: #03838f0d;
  content: "";
  background-color: var(--preview-color);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  border-radius: var(--radius);
  opacity: 0;
  pointer-events: none;
}
.preview-container:hover .preview::before {
  opacity: 1;
}
.preview-active {
  border-color: var(--color-primary);
}
/* .preview-container:hover .preview-active {
  border-color: var(--color-primary);
} */

.preview-number {
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  text-align: center;
}

.separator {
  height: 26px;
  width: 1px;
  background-color: var(--blue-gray100);
  margin: 0 5px 0 10px;
}

.canvas {
  width: 100%;
  height: 100%;
}
