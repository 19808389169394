html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Rubik-Medium", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

:root {
  --editor-height: 105mm;
  --editor-width: 74mm;

  /* GLOBAL COLORS */
  --color-primary: var(--light-blue);
  --color-secondary: var(--white);
  /* --color-background: var(--light-grey2); */
  --color-background: var(--light-grey3);

  /* COLORS */
  --dark-blue: #337289;
  --light-blue: #4184A9;
  --light-yellow: #E4C94C;  
  --light-grey3: #f7f7ff96;
  --deep-purple: #651fff;
  --light-black: rgba(0, 0, 0, 0.7);
  --light-grey: rgba(0, 0, 0, 0.04);
  --light-grey2: #e5e5e5;
  --blue-gray100: #cfd8dc;
  --blue-gray600: #546e7a;
  --blue-gray700: #455a64;
  --cyan800: #00838f;
  --white: #ffffff;
  --black: #000000;
  --wiggly-yellow: #f4b400;
  --amaranth-purple: #b7094cff;
  --jazzberry-jam: #a01a58ff;
  --amaranth-m-p: #892b64ff;
  --palatinate-purple: #723c70ff;
  --cyber-grape: #5c4d7dff;
  --bdazzled-blue: #455e89ff;
  --sapphire-blue: #2e6f95ff;
  --cg-blue: #1780a1ff;
  --blue-munsell: #0091adff;
  --offwhite: #f3f6ff;

  /* FONTS */
  --font-Nunito-bold: "Nunito-ExtraBold";
  --font-rubik-medium: "Rubik-Medium";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Rubik-Medium";
  src: local("Rubik-Medium"),
    url(./fonts/Rubik/static/Rubik-Medium.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "Nunito-ExtraBold";
  src: local("Nunito-ExtraBold"),
    url(./fonts/Nunito/Nunito-ExtraBold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
