.header {
  font-family: "Varela Round", sans-serif !important;
  height: 64px;
  background: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  flex-shrink: 0;
  /* box-shadow: 0px 5px 7px 0px #00000033; */
  position: relative;
  z-index: 100;
  border-bottom: 1px solid var(--blue-gray100);
}
.logo {
  height: 50px;
  padding-right: 10px;
}

.logoText {
  font-size: 24px;
  color: var(--color-primary);
  font-family: "Nunito-ExtraBold";
}

.beta {
  font-size: 13px;
}

.separator {
  height: 32px;
  width: 1px;
  background-color: var(--blue-gray100);
  margin: 0 16px;
}
.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.actions a {
  color: inherit;
  text-decoration: none;
}
.actions a:hover {
  text-decoration: underline;
}

.avatar {
  --size: 32px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 22px;
  width: var(--size);
  height: var(--size);
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: 1px solid var(--blue-gray100);
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-image {
  width: 100%;
}

.book-title {
  display: flex;
  gap: 10px;
  /* align-items: center; */
}

.book-edit-name-icon {
  cursor: pointer;
}

@media print {
  .header {
    display: none;
  }
}
